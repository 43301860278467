<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card outlined class="mb-6">
            <v-card-title
              class="d-flex justify-start align-center grey lighten-3"
            >
              <div class="flex-grow-1">Managment TAsk</div>
            </v-card-title>
            <v-divider></v-divider>

            <v-data-table
              :headers="tableHeaders"
              :items="tasks"
              no-data-text="No Tasks"
              :items-per-page="-1"
            >
              <template v-slot:item.task="{ item }">
                {{ item.task.name.replace("{title}", litter.dam.name) }}
              </template>

              <template v-slot:item.complete_by_date="{ item }">
                {{ formatDate(item.complete_by_date) }}
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  components: {},

  data() {
    return {
      searchTerm: "",
      tableHeaders: [
        { text: "Name", value: "task" },
        { text: "Status", value: "status" },
        { text: "For", value: "complete_by_date" },
      ],
    };
  },

  computed: {
    litter() {
      return this.$store.getters["lhl/litters/get"];
    },
    tasks() {
      let litter = this.$store.getters["lhl/litters/get"];

      let tasks = litter.tasks.filter((c) => {
        return c.task.task_category == 7;
      });

      return tasks;
    },
  },
};
</script>
